// Material Dashboard 2 React layouts

import Dashboard from "../src/layouts/dashboard";
import Tables from "../src/layouts/tables";
import Billing from "../src/layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
import Profile from "../src/layouts/profile";
import SignIn from "../src/layouts/authentication/sign-in";
import JoinMembership from "../src/layouts/Membership/JoinMembership";
import MemberShip from "../src/layouts/Membership/membership";
import HallManagement from "../src/layouts/hallManagement/HallManagement";
import EventManagement from "../src/layouts/eventManagement/EventManagement";
// import AddChamber from "layouts/chamber/AddChamber";
// import Chamber from "layouts/chamber";

// @mui icons
import Icon from "@mui/material/Icon";
import Hall from "../src/layouts/hallManagement/Hall";
import HallBooking from "../src/layouts/hallManagement/booking/hallBooking";
import EquipmentManagement from "../src/layouts/equipmentManagement/EquipmentManagement";
import Equipment from "../src/layouts/equipmentManagement/Equipment";
import MainHall from "../src/layouts/hallManagement/MainHall";
import Event from "../src/layouts/eventManagement/Event";
import ReportManagement from "../src/layouts/reports/reportManagement";
import MainLounge from "../src/layouts/loungeManagement/MainLounge";
import Lounge from "../src/layouts/loungeManagement/Lounge";
import LoungeManagement from "../src/layouts/loungeManagement/LoungeManagement";
import LoungeBooking from "../src/layouts/loungeManagement/booking/LoungeBooking";
const routes = (service_id: any) => {
  const adminRoutes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Membership",
      key: "membership",
      icon: <Icon fontSize="small">handshake</Icon>,
      route: "/membership",
      component: <MemberShip />,
    },
    {
      type: "collapse",
      name: "Report",
      key: "Report",
      icon: <Icon fontSize="small">bar_chart</Icon>,
      route: "/report",
      component: <ReportManagement />,
    },
    {
      key: "create_membership",
      route: "/membership/:tag",
      component: <JoinMembership />,
    },
    service_id?.includes(1) && {
      type: "collapse",
      name: "Hall Management",
      key: "hall-management",
      icon: <Icon fontSize="small">home</Icon>,
      route: "/hall-management",
      component: <MainHall />,
    },
    {
      key: "hall",
      route: "/hall",
      component: <HallManagement />,
    },
    {
      key: "add-hall",
      route: "/hall/:tag",
      component: <Hall />,
    },
    {
      key: "hall-booking",
      route: "/hall-management/booking",
      component: <HallBooking />,
    },
    {
      type: "collapse",
      name: "Lounge Management",
      key: "lounge-management",
      icon: <Icon fontSize="small">home</Icon>,
      route: "/lounge-management",
      component: <MainLounge />,
    },
    {
      key: "lounge",
      route: "/lounge",
      component: <LoungeManagement />,
    },
    {
      key: "add-lounge",
      route: "/lounge/:tag",
      component: <Lounge />,
    },
    {
      key: "lounge-booking",
      route: "/lounge-management/booking",
      component: <LoungeBooking />,
    },

    service_id?.includes(1) && {
      type: "collapse",
      name: "Equipment Management",
      key: "equipment-management",
      icon: <Icon fontSize="small">chair</Icon>,
      route: "/equipment-management",
      component: <EquipmentManagement />,
    },
    {
      key: "add-equipment",
      route: "/equipment-management/:tag",
      component: <Equipment />,
    },

    service_id?.includes(2) && {
      type: "collapse",
      name: "Event Management",
      key: "event-management",
      icon: <Icon fontSize="small">stadium</Icon>,
      route: "/event-management",
      component: <EventManagement />,
    },
    {
      key: "add-event",
      route: "/event-management/:tag",
      component: <Event />,
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: <Profile />,
    },
  ];
  const superAdminRoutes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Tables",
      key: "tables",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/tables",
      component: <Tables />,
    },
    {
      type: "collapse",
      name: "Billing",
      key: "billing",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/billing",
      component: <Billing />,
    },
    // {
    //   type: "collapse",
    //   name: "RTL",
    //   key: "rtl",
    //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    //   route: "/rtl",
    //   component: <RTL />,
    // },
    // {
    //   type: "collapse",
    //   name: "Chamber",
    //   key: "chamber",
    //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    //   route: "/chamber",
    //   component: <Chamber />,
    // },
    // {
    //   key: "add_chamber",
    //   route: "/chamber/add",
    //   component: <AddChamber />,
    // },
    {
      type: "collapse",
      name: "Membership",
      key: "membership",
      icon: <Icon fontSize="small">handshake</Icon>,
      route: "/membership",
      component: <MemberShip />,
    },
    {
      key: "create_membership",
      route: "/membership/:tag",
      component: <JoinMembership />,
    },
    {
      type: "collapse",
      name: "Hall Management",
      key: "hall-management",
      icon: <Icon fontSize="small">home</Icon>,
      route: "/hall-management",
      component: <HallManagement />,
    },
    // {
    //   type: "collapse",
    //   name: "Notifications",
    //   key: "notifications",
    //   icon: <Icon fontSize="small">notifications</Icon>,
    //   route: "/notifications",
    //   component: <Notifications />,
    // },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: <Profile />,
    },
    // {
    //   type: "collapse",
    //   name: "Sign in",
    //   key: "sign-in",
    //   icon: <Icon fontSize="small">login</Icon>,
    //   route: "/authentication/sign-in",
    //   component: <SignIn />,
    // },
  ];
  const userRoutes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Tables",
      key: "tables",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/tables",
      component: <Tables />,
    },
    {
      type: "collapse",
      name: "Billing",
      key: "billing",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/billing",
      component: <Billing />,
    },
    // {
    //   type: "collapse",
    //   name: "RTL",
    //   key: "rtl",
    //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    //   route: "/rtl",
    //   component: <RTL />,

    // },
    // {
    //   type: "collapse",
    //   name: "Chamber",
    //   key: "chamber",
    //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    //   route: "/chamber",
    //   component: <Chamber />,
    // },
    // {
    //   key: "add_chamber",
    //   route: "/chamber/add",
    //   component: <AddChamber />,
    // },
    {
      type: "collapse",
      name: "Membership",
      key: "membership",
      icon: <Icon fontSize="small">handshake</Icon>,
      route: "/membership",
      component: <MemberShip />,
    },
    {
      key: "create_membership",
      route: "/membership/:tag",
      component: <JoinMembership />,
    },
    {
      type: "collapse",
      name: "Hall Management",
      key: "hall-management",
      icon: <Icon fontSize="small">home</Icon>,
      route: "/hall-management",
      component: <HallManagement />,
    },
    // {
    //   type: "collapse",
    //   name: "Notifications",
    //   key: "notifications",
    //   icon: <Icon fontSize="small">notifications</Icon>,
    //   route: "/notifications",
    //   component: <Notifications />,
    // },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: <Profile />,
    },
    // {
    //   type: "collapse",
    //   name: "Sign in",
    //   key: "sign-in",
    //   icon: <Icon fontSize="small">login</Icon>,
    //   route: "/authentication/sign-in",
    //   component: <SignIn />,
    // },
  ];

  const notLoggedInRoutes = [
    {
      type: "collapse",
      name: "Sign In",
      key: "sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/authentication/sign-in",
      component: <SignIn />,
    },
  ];

  return {
    superAdminRoutes,
    adminRoutes,
    userRoutes,
    notLoggedInRoutes,
  };
};
export default routes;
